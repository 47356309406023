import { amgApi } from "@/service/axios";

class SpecialistsDashboardService {
  async getCardsData(params) {
    try {
      const { data } = await amgApi.post(
        "specialists/get-cards-data-v2",
        params
      );
      return data;
    } catch (error) {
      console.error("Something went wrong on getCardsData", error);
    }
  }
  async getChartData(params) {
    try {
      const { data } = await amgApi.post(
        "specialists-digital/get-chart-data",
        params
      );
      return data;
    } catch (error) {
      console.error("Something went wrong on getChartData", error);
    }
  }
  async getModalData(params) {
    try {
      const data = await amgApi.post("specialists/get-modal-data", params);
      return data;
    } catch (error) {
      console.error("Something went wrong on getModalData", error);
    }
  }
  async getChartNcrPayment(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-chart-ncr-payment",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getChartNcrPayment", error);
    }
  }
  async getChartNcrAuto(params) {
    try {
      const data = await amgApi.post("specialists/get-chart-ncr-auto", params);
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getChartNcrAuto", error);
    }
  }

  async getReportBalanceDoughnut(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-report-balance-doughnut",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getProgressBalance", error);
    }
  }

  async getReportBalanceBar(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-report-balance-bar",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getReportBalanceDoughnut", error);
    }
  }

  async getReportModalDash(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-report-modal-dash",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getReportBalanceDoughnut", error);
    }
  }

  async getModules(params) {
    try {
      const data = await amgApi.post("specialists/get-modules", params);
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getModules", error);
    }
  }
  async getAnalystData(params) {
    try {
      const data = await amgApi.post("specialists/get-analyst-data", params);
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getAnalystData", error);
    }
  }
  async getAnalystCardData(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-analyst-card-data",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getAnalystCardData", error);
    }
  }
  async getDisputeChart(params) {
    try {
      const data = await amgApi.post("specialists/get-dispute-chart", params);
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getDisputeChart", error);
    }
  }
  async getDisputeByBureau(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-dispute-by-bureau",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getDisputeByBureau", error);
    }
  }

  async getDeleteAccsByBureau(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-delete-accs-by-bureau",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getDeleteAccsByBureau", error);
    }
  }

  async getDisputeByProgram(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-disputes-by-program",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on getDisputeByProgram", error);
    }
  }
  async getDisputesBySpecialist(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-disputes-by-specialist",
        params
      );
      return data.data;
    } catch (error) {
      console.error("Something went wrong on DisputesBySpecialist", error);
    }
  }

  async getDashboardCardDisputeDetail(params) {
    try {
      const {data} = await amgApi.post(
        "specialists/get-dashboard-card-dispute-detail",
        params
      );
      return data;
    } catch (error) {
      console.error("Something went wrong on DisputesBySpecialist", error);
    }
  }
}
export default new SpecialistsDashboardService();
