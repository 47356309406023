<template>
  <div>
    <b-row class="mb-1" v-if="cards.length == 0">
      <b-col xl="3" xs="6" class="p-1" v-for="(_, index) in 4" :key="index">
        <b-skeleton
          no-aspect
          height="calc(14rem * var(--scale))"
          class="rounded"
        />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        xl="3"
        xs="6"
        class="p-1"
        v-for="(card, index) in cards"
        :key="index"
      >
        <DisputeCard
          :info="card"
          :isYear="month == null"
          :month="month"
          :year="year"
          :specialist="specialist"
          :department="department"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DisputeCard from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/cards/DisputeCard.vue";
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import { mapGetters } from "vuex";

export default {
  components: {
    DisputeCard,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    specialist: {
      type: Number,
      default: null,
    },
    department: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      cards: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.getCardData();
  },
  methods: {
    async getCardData() {
      this.cards = [];
      try {
        const data = await SpecialistsDashboardService.getAnalystData({
          year: this.year,
          month: this.month,
          user_id: this.currentUser.role_id == 22 ? this.currentUser.user_id : this.specialist,
          module_id: this.department,
        });
        this.cards = data || [];
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    year() {
      this.getCardData();
    },
    month() {
      this.getCardData();
    },
    specialist() {
      this.getCardData();
    },
    department() {
      this.getCardData();
    }
  },
};
</script>

<style></style>
