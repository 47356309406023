var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalName",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":((_vm.disputeType.toUpperCase()) + " DISPUTE GRID"),"size":"lg","hide-footer":"","centered":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.closeModal()}}},[_c('b-container',[_c('filter-slot',{attrs:{"filter":[],"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['disputeTable'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"disputeTable",staticClass:"m-0 p-0",attrs:{"items":_vm.myProvider,"fields":_vm.fields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy,"sticky-header":"500px","show-empty":"","responsive":"","small":""},on:{"update:currentPage":function($event){return _vm.$set(_vm.paginate, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.paginate, "currentPage", $event)},"update:perPage":function($event){return _vm.$set(_vm.paginate, "perPage", $event)},"update:per-page":function($event){return _vm.$set(_vm.paginate, "perPage", $event)},"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('IconStatusAccount',{attrs:{"status":Number(item.account_status),"fromBc":""}}),_c('router-link',{attrs:{"target":"_blank","to":{
                    name: _vm.clientDashboardRouteName,
                    params: {
                      idClient: ("" + (item.account_id)),
                    },
                  }}},[_vm._v(" "+_vm._s(item.client_name)+" ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block",staticStyle:{"margin-left":"18px"}},[_vm._v(" "+_vm._s(item.account)+" ")])])]}},{key:"cell(created_by)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.created_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.creation_date))+" ")])]}},{key:"cell(completed_by)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.completed_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.completion_date))+" ")])]}}])})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }