<template>
  <b-card class="h-100 mb-0" body-class="h-100 pie-dispute">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="font-weight-bolder title-card mt-1">
          Disputes by Specialist
        </div>
        <div class="text-muted">Overview Disputes</div>
      </div>
    </div>
    <GroupBar v-if="disputeOfMonth.length > 0" :disputes="disputeOfMonth" />
    <template v-else>
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 365px"
      >
        <b-spinner class="m-1" variant="primary" v-if="!disputeOfMonth" />
        <div v-else>No data</div>
      </div>
    </template>
  </b-card>
</template>

<script>
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import GroupBar from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/charts/GroupBar.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    GroupBar,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    specialist: {
      type: Number,
      default: null,
    },
    department: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      disputes: [],
      disputeOfMonth: [],
    };
  },
  mounted() {
    this.getDisputeBySpecialist();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    optionYears() {
      const currentYear = new Date().getFullYear();
      const stopYear = 2022;
      const years = [];
      for (let index = currentYear; index >= stopYear; index--) {
        years.push({ text: index, value: index });
      }
      return years;
    },
    optionMonths() {
      const months = [];
      for (let index = 0; index < 12; index++) {
        let nameMonth = new Date(0, index).toLocaleString("en-us", {
          month: "long",
        });
        months.push({ text: nameMonth, value: index + 1 });
      }
      return months;
    },
  },
  methods: {
    async getDisputeBySpecialist() {
      try {
        const data = await SpecialistsDashboardService.getDisputesBySpecialist({
          year: this.year,
          user_id:
            this.currentUser.role_id == 22 ? this.currentUser.user_id : this.specialist,
          module_id: this.department,
        });
        this.disputes = data || [];
        this.disputeOfMonth = this.month
          ? this.disputes.filter((dispute) => dispute.month === this.month)
          : this.disputes.reduce((acc, item) => {
              const existingItem = acc.find(
                (el) => el.specialist === item.specialist
              );

              if (existingItem) {
                existingItem.total += item.total;
              } else {
                acc.push({
                  month: item.month,
                  specialist: item.specialist,
                  total: item.total,
                });
              }

              return acc;
            }, []);
      } catch (error) {
        console.error(error);
      }
    },
    refreshCard() {
      this.getDisputeBySpecialist();
    },
  },
  watch: {
    year() {
      this.refreshCard();
    },
    month() {
      this.refreshCard();
    },
    specialist() {
      this.refreshCard();
    },
    department() {
      this.refreshCard();
    }
  },
};
</script>

<style scoped>
.title-card {
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
