<template>
  <div class="card-data">
    <div class="card-data__header">
      <div class="card-data__content">
        <div class="card-data__value">
          {{ info.total }}
          <span class="card-data__title text-uppercase">
            {{ info.title }}
          </span>
        </div>
      </div>
      <div
        class="card-data__icon cursor-pointer"
        :class="{ dark: isDarkSkin }"
        @click="openCardDetail()"
      >
        <feather-icon :icon="cardIcons[info.title]" size="20" />
      </div>
    </div>
    <div class="title-card">
      Current {{ isYear ? "year" : "month" }}&emsp;:&emsp;
      <span class="font-strong" v-b-tooltip.hover title="Completed">
        {{ info.completed_of_month }}
      </span>
      <span class="font-strong" v-b-tooltip.hover title="Pending">
        / {{ info.pending_of_month + info.completed_of_month }}
      </span>
    </div>
    <div
      class="title-card"
      v-b-tooltip.hover
      :title="`Completed in the current ${
        isYear ? 'year' : 'month'
      }, but created in previous ${isYear ? 'years' : 'months'}`"
    >
      Previous {{ isYear ? "years" : "months" }}:&emsp;
      <span class="font-strong"> {{ info.completed_other_month }} </span>
    </div>

    <card-grid-dashboard
      v-if="showGridModal"
      :disputeType="info.title"
      :info="info"
      :month="month"
      :year="year"
      :specialist="specialist"
      :department="department"
      @close="showGridModal = false"
    />
  </div>
</template>

<script>
import CardGridDashboard from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/modal/CardGridDashboard.vue";

export default {
  props: {
    isYear: {
      type: Boolean,
      default: false,
    },
    month: {
      type: Number,
    },
    year: {
      type: Number,
    },
    specialist: {
      type: Number,
      default: null,
    },
    department: {
      type: Number,
      default: null,
    },
    info: {
      type: Object,
      default: () => {
        return {
          title: "Title",
          subtitle: "Subtitle",
          total: 0,
          pending: 0,
          done: 0,
        };
      },
    },
  },
  components: {
    CardGridDashboard,
  },
  data() {
    return {
      cardIcons: {
        Workplans: "FileTextIcon",
        Updates: "RefreshCcwIcon",
        Directs: "PhoneIcon",
        Letters: "MailIcon",
      },
      showGridModal: false,
      disputeType: null,
    };
  },
  methods: {
    openCardDetail() {
      this.showGridModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-data__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  .card-data__pending {
    font-size: calc(1.8rem * var(--scale));
    font-weight: 500;
    color: var(--text-color);
  }
  .card-data__done {
    font-size: calc(1.8rem * var(--scale));
    font-weight: 500;
    color: var(--text-color);
  }
}

.card-data__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 0.5rem 2rem;
  .card-data__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .card-data__title {
      font-size: calc(1.2rem * var(--scale));
      font-weight: 600;
      color: var(--text-color);
    }
    .card-data__value {
      font-size: calc(2.4rem * var(--scale));
      font-weight: 700;
      color: var(--text-color);
      margin-bottom: 0.3rem;
      .card-data__value__sub {
        font-size: calc(1.2rem * var(--scale));
        font-weight: 500;
      }
    }
  }
  .card-data__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    box-shadow: 0 0 0.3rem 0 rgba(105, 105, 105, 0.2);
    background-color: white;
    color: #272727;
    &.dark {
      background-color: #272727;
      color: white;
      box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0);
    }
  }
}

.card-data {
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  background: linear-gradient(90deg, #0563bd 0%, #0097b7 79%);
  color: white;
  padding-bottom: 1rem;
}

@media (max-width: 480px) {
  .card-data__footer {
    .card-data__pending {
      font-size: calc(1.6rem * var(--scale));
    }
    .card-data__done {
      font-size: calc(1.6rem * var(--scale));
    }
  }
}

.title-card {
  font-size: calc(1.3rem * var(--scale));
  font-weight: 500;
  padding: 0.5rem 2rem;
}
</style>
