<template>
  <div>
    <VueApexCharts
      class="d-flex justify-content-end"
      :options="optionsComputed"
      :series="seriesComputed"
      height="350px"
      width="100%"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    disputes: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "direct",
    },
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      donutOptions: {
        series: [200, 100, 50],
        chart: {
          toolbar: {
            show: false,
          },
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2.5,
        },
        labels: ["Experian", "Equifax", "Transunion"],
        colors: ["#30248c", "#ad002e", "#00a6ca"],
      },
    };
  },
  computed: {
    optionsComputed() {
      return {
        ...this.donutOptions,
        tooltip: {
          theme: this.isDarkSkin ? "dark" : "light",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  fontSize: this.screenWidth > 600 ? "30px" : "14px",
                  fontWeight: "600",
                  color: this.isDarkSkin ? "#fff" : "#000",
                },
                value: {
                  show: true,
                  fontSize: this.screenWidth > 600 ? "30px" : "14px",
                  fontWeight: "600",
                  color: this.isDarkSkin ? "#fff" : "#000",
                },
              },
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          itemMargin: {
            horizontal: 15,
            vertical: 15,
          },
          labels: {
            colors: this.isDarkSkin ? "#fff" : "#000",
          },
          formatter: function (seriesName, opts) {
            opts.total = opts.w.globals.series[opts.seriesIndex];
            if (opts.seriesIndex == 0) {
              opts.image = "/assets/images/bureaus/experian.png";
            } else if (opts.seriesIndex == 1) {
              opts.image = "/assets/images/bureaus/equifax.png";
            } else {
              opts.image = "/assets/images/bureaus/transunion.png";
            }
            return `<img src="${opts.image}" width="20px" height="20px" /> <span style="font-size: 14px; font-weight:400"> (${opts.total}) </span>`;
          },
        },
        stroke: {
          show: true,
          colors: this.isDarkSkin ? "#17161a" : "#ffffff",
          width: 4,
        },
      };
    },
    seriesComputed() {
      const { exp, eqr, tur } = this.disputes;
      return [Number(exp), Number(eqr), Number(tur)];
    },
  },
};
</script>

<style scoped></style>
