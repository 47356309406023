<template>
  <div>
    <b-modal
      ref="modalName"
      modal-class="modal-primary"
      title-class="h3 text-white"
      :title="`${disputeType.toUpperCase()} DISPUTE GRID`"
      size="lg"
      @hidden="closeModal()"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <b-container>
        <filter-slot
          :filter="[]"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['disputeTable'].refresh()"
        >
          <template #table>
            <b-table
              ref="disputeTable"
              class="m-0 p-0"
              :items="myProvider"
              :fields="fields"
              :current-page.sync="paginate.currentPage"
              :per-page.sync="paginate.perPage"
              :busy.sync="isBusy"
              sticky-header="500px"
              show-empty
              responsive
              small
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>

              <template #cell(client_name)="{ item }">
                <span class="d-flex align-items-center" style="gap: 5px">
                  <IconStatusAccount
                    :status="Number(item.account_status)"
                    fromBc
                  />
                  <router-link
                    target="_blank"
                    :to="{
                      name: clientDashboardRouteName,
                      params: {
                        idClient: `${item.account_id}`,
                      },
                    }"
                  >
                    {{ item.client_name }}
                  </router-link>
                </span>

                <div class="d-flex align-items-center">
                  <span class="d-block" style="margin-left: 18px">
                    {{ item.account }}
                  </span>
                </div>
              </template>

              <template #cell(created_by)="data">
                <div>
                  {{ data.item.created_by }}
                  <br />
                  {{ data.item.creation_date | myGlobalDay }}
                </div>
              </template>

              <template #cell(completed_by)="data">
                <div>
                  {{ data.item.completed_by }}
                  <br />
                  {{ data.item.completion_date | myGlobalDay }}
                </div>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import DashboardV2Service from "@/views/specialists/views/dashboard_v2/service/index.js";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IconStatusAccount,
  },
  props: {
    disputeType: {
      type: String,
      required: true,
    },
    month: {
      type: Number,
    },
    year: {
      type: Number,
    },
    specialist: {
      type: Number,
      default: null,
    },
    department: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      isBusy: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalRows: 0,
      startPage: 1,
      toPage: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client Name or Account",
        model: "",
      },
      fields: [
        {
          key: "client_name",
          label: "CLIENT",
        },
        {
          key: "program_name",
          label: "PROGRAM",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_by",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "completed_by",
          label: "COMPLETED BY",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      filters: [
        {
          key: "program",
          type: "select",
          margin: true,
          showLabel: true,
          label: "PROGRAM",
          model: null,
          options: [
            { id: 1, text: "Business" },
            { id: 2, text: "Boost Credit" },
            { id: 3, text: "Credit Experts" },
            { id: 4, text: "Debt Solution" },
          ],
          reduce: "id",
          selectText: "text",
          cols: 12,
          visible: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
  },
  mounted() {
    this.toggleModal("modalName");
  },
  created() {
    if (this.disputeType == "Directs" || this.disputeType == "Letters") {
      this.fields[2].label = "PROCESSED BY";
    } else if (this.disputeType == "Workplans") {
      this.fields[2].label = "ASSIGNED TO";
    } else {
      this.fields[2].label = "REVIEWED BY";
    }
  },
  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          perPage: ctx.perPage,
          nPage: ctx.currentPage,
          month: this.month,
          year: this.year,
          type_dispute: this.getTypeDispute(),
          search: this.filterPrincipal.model,
          user_id:
            this.currentUser.role_id == 22
              ? this.currentUser.user_id
              : this.specialist,
          module_id: this.department,
        };

        const data = await DashboardV2Service.getDashboardCardDisputeDetail(
          params
        );

        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        return data.data || [];
      } catch (error) {
        return [];
      }
    },
    getTypeDispute() {
      const types = {
        Workplans: 1,
        Updates: 2,
        Directs: 3,
        Letters: 4,
      };

      return types[this.disputeType];
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>