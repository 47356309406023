<template>
  <div>
    <b-row class="mb-1">
      <b-col xl="8" xs="12" class="p-1">
        <b-card class="h-100 mb-0" body-class="h-100">
          <b-row>
            <b-col xl="3" xs="12">
              <div class="font-weight-bolder title-card my-1">
                Select Type Dispute
              </div>
              <b-form-radio-group
                id="method"
                v-model="type"
                button-variant="outline-primary"
                :options="[
                  { text: 'LETTERS', value: 'letters' },
                  { text: 'DIRECT', value: 'direct' },
                ]"
                buttons
              />
              <div class="font-weight-bolder title-card mt-3" text-capitalize>
                Disputes
              </div>
              <div class="text-muted text-capitalize">
                Overview {{ typeChart }} Disputes
              </div>
              <div class="mt-3">
                <div class="value-item">{{ totalRequested }}</div>
                <div class="text-muted text-capitalize">
                  Current {{ typeChart }} Disputes Requested
                </div>
                <div class="value-item mt-3">{{ totalCompleted }}</div>
                <div class="text-muted text-capitalize">
                  Current {{ typeChart }} Disputes Completed
                </div>
              </div>
            </b-col>
            <b-col xl="9" xs="12">
              <DisputeLines
                v-if="rounds"
                :rounds="rounds"
                :type="type"
                :type-chart="typeChart"
                :key="type"
              />
              <template v-else>
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="min-height: 380px"
                >
                  <b-spinner class="m-1" variant="primary" />
                </div>
              </template>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col xl="2" xs="6" class="p-1">
        <DisputeBureau :typePie="1" :year="year" :month="month" :specialist="specialist" :department="department" />
      </b-col>
      <b-col xl="2" xs="6" class="p-1">
        <DisputeBureau :typePie="2" :year="year" :month="month" :specialist="specialist" :department="department" />
      </b-col>
      <b-col xl="6" xs="6" class="p-1">
        <DisputeProgram :year="year" :month="month" :specialist="specialist" :department="department" />
      </b-col>
      <b-col xl="6" xs="6" class="p-1">
        <DisputeSpecialist :year="year" :month="month" :specialist="specialist" :department="department" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// charts
import DisputeLines from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/charts/DisputeLines.vue";
// services
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
// cards
import DisputeBureau from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/DisputeBureau.vue";
import DisputeProgram from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/DisputeProgram.vue";
import DisputeSpecialist from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/DisputeSpecialist.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // charts
    DisputeLines,
    // cards
    DisputeBureau,
    DisputeProgram,
    DisputeSpecialist,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    specialist: {
      type: Number,
      default: null,
    },
    department: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      type: "letters",
      rounds: null,
      typeChart: "year",
    };
  },
  mounted() {
    this.typeChart = this.month == null ? "year" : "month";
    this.getDisputeChart();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    optionYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let index = 0; index < 2; index++) {
        years.push({ text: currentYear - index, value: currentYear - index });
      }
      return years;
    },
    optionMonths() {
      const months = [];
      for (let index = 0; index < 12; index++) {
        let nameMonth = new Date(0, index).toLocaleString("en-us", {
          month: "long",
        });
        months.push({ text: nameMonth, value: index + 1 });
      }
      return months;
    },
    totalRequested() {
      if (!this.rounds) return 0;
      let { directs, letters } = this.rounds;
      let sum_direct = directs.requested.reduce((acc, cur) => acc + cur, 0);
      let sum_letters = letters.requested.reduce((acc, cur) => acc + cur, 0);
      return this.type == "direct" ? sum_direct : sum_letters;
    },
    totalCompleted() {
      if (!this.rounds) return 0;
      let { directs, letters } = this.rounds;
      let sum_direct = directs.completed.reduce((acc, cur) => acc + cur, 0);
      let sum_letters = letters.completed.reduce((acc, cur) => acc + cur, 0);
      return this.type == "direct" ? sum_direct : sum_letters;
    },
  },
  methods: {
    async getDisputeChart() {
      try {
        this.rounds = null;
        const data = await SpecialistsDashboardService.getDisputeChart({
          year: this.year,
          month: this.month,
          type: this.month == null ? "year" : "month",
          user_id:
            this.currentUser.role_id == 22
              ? this.currentUser.user_id
              : this.specialist,
          module_id: this.department,
        });
        this.rounds = data || null;
      } catch (error) {
        console.error(error);
      }
    },
    async refreshLines() {
      this.getDisputeChart();
    },
  },
  watch: {
    year() {
      this.typeChart = this.month == null ? "year" : "month";
      this.refreshLines();
    },
    month() {
      this.typeChart = this.month == null ? "year" : "month";
      this.refreshLines();
    },
    specialist() {
      this.refreshLines();
    },
    department() {
      this.refreshLines();
    }
  },
};
</script>

<style lang="scss" scoped>
.first-content {
  display: flex;
  flex-wrap: wrap;
}
.dash-content {
  display: flex;
  gap: 15px;
}
.title-card {
  font-size: 1.2rem;
  font-weight: 600;
}
.value-item {
  font-size: 1.8rem;
  font-weight: 600;
}
.logo-size {
  width: 85px;
  height: 85px;
}
.h-100 {
  height: 100%;
}
.pie-dispute {
  & > div {
    width: 100%;
  }
}
.item-action {
  flex: 0 0 10%;
}
</style>
