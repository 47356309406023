<template>
  <div>
    <header-slot> </header-slot>
    <div class="d-flex justify-content-end align-items-center mb-1 gap-10">
      <b-form-select
        class="item-flex"
        v-model="department"
        :options="optionDepartments"
      />
      <b-form-select
        class="item-flex"
        v-model="agent"
        :options="optionAgents"
        v-if="isCeo || isSupervisor || isChief"
      />
      <b-form-select class="item-flex" v-model="year" :options="optionYears" />
      <b-form-select
        class="item-flex"
        v-model="month"
        :options="optionMonths"
      />
      <b-button variant="primary" @click="refreshAll">
        <feather-icon icon="RefreshCcwIcon" />
      </b-button>
    </div>
    <CardSection
      :year="year"
      :month="month"
      :specialist="agent"
      :department="department"
      :key="keyCards + 1"
    />
    <DisputeSection
      :year="year"
      :month="month"
      :specialist="agent"
      :department="department"
      :key="keyCards + 2"
    />
  </div>
</template>

<script>
import CardSection from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/CardSection.vue";
import DisputeSection from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/DisputeSection.vue";
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

export default {
  components: {
    CardSection,
    DisputeSection,
  },
  data() {
    return {
      keyCards: 0,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      optionAgents: [],
      agent: null,
      optionDepartments: [
        { value: null, text: "All departments" },
        { value: 14, text: "Bookeeping" },
        { value: 7, text: "Boost Credit" },
        { value: 3, text: "Business" },
        { value: 10, text: "Court Info" },
        { value: 6, text: "Credit Experts" },
        { value: 5, text: "Debt Solution" },
        { value: 12, text: "Paragon" },
        { value: 11, text: "Specialists" },
        { value: 8, text: "Tax Research" },
      ],
      department: null,
    };
  },
  created() {
    this.getAgentOptions();
  },
  methods: {
    refreshAll() {
      this.keyCards++;
    },
    async getAgentOptions() {
      const { users } = await RequestWorkPlanService.getCreditAnalysts();
      users.sort(function (a, b) {
        let textA = a.text.toUpperCase();
        let textB = b.text.toUpperCase();

        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });

      users.forEach((user) => {
        this.optionAgents.push({ text: user.text, value: user.id });
      });

      this.optionAgents.unshift({ text: "All specialists", value: null });
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    optionYears() {
      const currentYear = new Date().getFullYear();
      const stopYear = 2022;
      const years = [];
      for (let index = currentYear; index >= stopYear; index--) {
        years.push({ text: index, value: index });
      }
      return years;
    },
    optionMonths() {
      const months = [];
      for (let index = 0; index < 12; index++) {
        let nameMonth = new Date(0, index).toLocaleString("en-us", {
          month: "long",
        });
        months.push({ text: nameMonth, value: index + 1 });
      }
      months.unshift({ text: "All", value: null });
      return months;
    },
  },
};
</script>

<style scoped>
.item-flex {
  flex: 0 0 10%;
}
</style>