<template>
  <b-card class="h-100 mb-0" body-class="h-100 pie-dispute">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="font-weight-bolder title-card mt-1">{{ isPieForDispute ? 'Disputed' : 'Deleted' }} Accounts by Bureau</div>
        <div class="text-muted">Overview Accounts</div>
      </div>
    </div>
    <DisputePie v-if="disputeOfMonth" :disputes="disputeOfMonth" />
    <template v-else>
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 365px"
      >
        <b-spinner class="m-1" variant="primary" />
      </div>
    </template>
  </b-card>
</template>

<script>
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import DisputePie from "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/charts/DisputePie.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DisputePie,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
     specialist: {
      type: Number,
      default: null,
    },
    department: {
      type: Number,
      default: null,
    },
    typePie: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      disputes: null,
      disputeOfMonth: null,
    };
  },
  mounted() {
    this.getDisputeByBureau();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isPieForDispute() {
      return this.typePie == 1;
    },
    optionYears() {
      const currentYear = new Date().getFullYear();
      const stopYear = 2022;
      const years = [];
      for (let index = currentYear; index >= stopYear; index--) {
        years.push({ text: index, value: index });
      }
      return years;
    },
    optionMonths() {
      const months = [];
      for (let index = 0; index < 12; index++) {
        let nameMonth = new Date(0, index).toLocaleString("en-us", {
          month: "long",
        });
        months.push({ text: nameMonth, value: index + 1 });
        months.unshift({ text: "All", value: 0 });
      }
      return months;
    },
  },
  methods: {
    async getDisputeByBureau() {
      const method = this.isPieForDispute ? "getDisputeByBureau" : "getDeleteAccsByBureau";

      this.disputes = null;
      const data = await SpecialistsDashboardService[method]({
        month: this.month,
        year: this.year,
        user_id:
          this.currentUser.role_id == 22 ? this.currentUser.user_id : this.specialist,
        module_id: this.department
      });
      this.disputes = data || [];
      if (this.month == null) {
        return await this.getDisputeByYear();
      }
      this.disputeOfMonth = data.find((item) => item.month === this.month) || {
        tur: 0,
        exp: 0,
        eqr: 0,
      };
    },
    async getDisputeByMonth() {
      this.disputeOfMonth = null;
      if (this.disputes && this.disputes.length > 0) {
        if (this.month == null) {
          return await this.getDisputeByYear();
        }
        this.disputeOfMonth = this.disputes.find(
          (item) => item.month === this.month
        ) || { tur: 0, exp: 0, eqr: 0 };
      } else {
        await this.getDisputeByBureau();
      }
    },

    async getDisputeByYear() {
      let tur = this.disputes.reduce((acc, item) => {
        return acc + Number(item.tur);
      }, 0);
      let exp = this.disputes.reduce((acc, item) => {
        return acc + Number(item.exp);
      }, 0);
      let eqr = this.disputes.reduce((acc, item) => {
        return acc + Number(item.eqr);
      }, 0);
      this.disputeOfMonth = { tur, exp, eqr };
    },
    refreshPie() {
      this.getDisputeByBureau();
    },
  },
  watch: {
    year() {
      this.getDisputeByBureau();
    },
    month() {
      this.getDisputeByMonth();
    },
    specialist() {
      this.getDisputeByBureau();
    },
    department() {
      this.getDisputeByBureau();
    }
  },
};
</script>

<style scoped>
.title-card {
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
