<template>
  <div>
    <VueApexCharts
      class="d-flex justify-content-end"
      width="100%"
      height="350px"
      :options="optionsComputed"
      :series="seriesComputed"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    type: {
      type: String,
      default: "direct",
    },
    disputes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      areaChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2.5,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
      },
      colors: [
        "#03A9F4",
        "#66DA26",
        "#546E7A",
        "#E91E63",
        "#FF9800",
        "#FF4560",
        "#775DD0",
        "#3F51B5",
        "#03A9F4",
        "#4CAF50",
      ],
    };
  },
  computed: {
    optionsComputed() {
      return {
        ...this.areaChartOptions,
        tooltip: {
          theme: this.isDarkSkin ? "dark" : "light",
        },
        yaxis: {
          labels: {
            style: {
              colors: this.isDarkSkin ? "#fff" : "#000",
            },
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
          labels: {
            style: {
              colors: this.isDarkSkin ? "#fff" : "#000",
            },
          },
          axisBorder: {
            show: false,
          },
          categories: this.disputes.map((item) => item.value),
        },
        plotOptions: {
          bar: {
            borderRadius: 15,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        colors: this.colorsComputed,
      };
    },
    seriesComputed() {
      return [
        {
          name: "Disputes",
          data: this.disputes.map((item) => item.total),
        },
      ];
    },
    colorsComputed() {
      const colors = {
        1: "#be0026",
        2: "#00a6ca",
        3: "#ffc000",
        4: "#0e5bd9",
        5: "#FF9800",
        6: "#FF4560",
        7: "#775DD0",
      };
      return this.disputes.map((item) => colors[item.program_id]);
    },
  },
};
</script>

<style></style>
