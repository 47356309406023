<template>
  <div>
    <VueApexCharts
      class="d-flex justify-content-end"
      width="100%"
      height="380px"
      :options="optionsComputed"
      :series="seriesComputed"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    rounds: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "direct",
    },
    typeChart: {
      type: String,
      default: "year",
    },
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      areaChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2.5,
        },
        colors: ["#FFB800", "#00D0FF"],
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
      },
    };
  },
  computed: {
    optionsComputed() {
      return {
        ...this.areaChartOptions,
        tooltip: {
          theme: this.isDarkSkin ? "dark" : "light",
        },
        yaxis: {
          labels: {
            style: {
              colors: this.isDarkSkin ? "#fff" : "#000",
            },
          },
          axisTicks: {
            show: true,
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: this.isDarkSkin ? "#fff" : "#000",
            },
          },
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          categories: this.categoriesComputed,
        },
        legend: {
          ...this.areaChartOptions.legend,
          itemMargin: {
            horizontal: 15,
          },
          labels: {
            colors: this.isDarkSkin ? "#fff" : "#000",
          },
        },
      };
    },
    seriesComputed() {
      let requested = [];
      let completed = [];
      if (this.type == "direct") {
        requested = this.rounds.directs.requested;
        completed = this.rounds.directs.completed;
      } else {
        requested = this.rounds.letters.requested;
        completed = this.rounds.letters.completed;
      }
      return [
        {
          name: "Requested",
          data: requested,
        },
        {
          name: "Completed",
          data: completed,
        },
      ];
    },
    categoriesComputed() {
      if (this.typeChart == "year") {
        return [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
      } else {
        let days = [];
        const totalDays = this.seriesComputed[0].data.length;
        for (let index = 0; index < totalDays; index++) {
          days.push(index + 1);
        }
        return days;
      }
    },
  },
};
</script>

<style></style>
